"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const namespaced = true;
const state = {
    loaded: false,
    loggedin: false,
    email: "",
    userId: ""
};
const getters = {
    isLoggedIn: (state) => state.loggedin,
    getEmail: (state) => state.email,
    getUserId: (state) => state.userId
};
const actions = {};
const mutations = {
    setLoggedIn: (state, loggedin) => { state.loggedin = loggedin; },
    setLoaded: (state, loaded) => { state.loaded = loaded; },
    setEmail: (state, email) => { state.email = email; },
    setUserId: (state, userId) => { state.userId = userId; }
};
exports.default = {
    namespaced, state, getters, actions, mutations
};
