"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KennzeichenList = void 0;
exports.KennzeichenList = [{
        "kennzeichen": "A",
        "stadt_landkreis": "Augsburg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "AA",
        "stadt_landkreis": "Aalen Ostalbkreis",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "AB",
        "stadt_landkreis": "Aschaffenburg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "ABI",
        "stadt_landkreis": "Anhalt-Bitterfeld",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "ABG",
        "stadt_landkreis": "Altenburger Land",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "AC",
        "stadt_landkreis": "Aachen",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "AE",
        "stadt_landkreis": "Auerbach",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "AIC",
        "stadt_landkreis": "Aichach-Friedberg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "AK",
        "stadt_landkreis": "Altenkirchen/Westerwald",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "AM",
        "stadt_landkreis": "Amberg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "AN",
        "stadt_landkreis": "Ansbach",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "AÖ",
        "stadt_landkreis": "Altötting",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "AP",
        "stadt_landkreis": "Apolda - Weimarer Land",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "AS",
        "stadt_landkreis": "Amberg-Sulzbach",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "AUR",
        "stadt_landkreis": "Aurich",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "AW",
        "stadt_landkreis": "Bad Neuenahr-Ahrweiler",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "AZ",
        "stadt_landkreis": "Alzey-Worms",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "B",
        "stadt_landkreis": "Berlin",
        "bundesland": "Berlin"
    }, {
        "kennzeichen": "BA",
        "stadt_landkreis": "Bamberg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "BAD",
        "stadt_landkreis": "Baden-Baden",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "BAR",
        "stadt_landkreis": "Barnim",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "BB",
        "stadt_landkreis": "Böblingen",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "BC",
        "stadt_landkreis": "Biberach/Riß",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "BD",
        "stadt_landkreis": "Bundestag, Bundesrat, Bundesregierung",
        "bundesland": ""
    }, {
        "kennzeichen": "BGL",
        "stadt_landkreis": "Berchtesgadener Land",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "BI",
        "stadt_landkreis": "Bielefeld",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "BIR",
        "stadt_landkreis": "Birkenfeld/Nahe und Idar-Oberstein",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "BIT",
        "stadt_landkreis": "Bitburg-Prüm",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "BK",
        "stadt_landkreis": "Bochum",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "BL",
        "stadt_landkreis": "Zollernalbkreis / Balingen",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "BLK",
        "stadt_landkreis": "Burgenlandkreis",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "BM",
        "stadt_landkreis": "Erftkreis / Bergheim",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "BN",
        "stadt_landkreis": "Bonn",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "BO",
        "stadt_landkreis": "Bochum",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "BOR",
        "stadt_landkreis": "Borken / Ahaus",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "BOT",
        "stadt_landkreis": "Bottrop",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "BP",
        "stadt_landkreis": "Bundespolizei",
        "bundesland": ""
    }, {
        "kennzeichen": "BRA",
        "stadt_landkreis": "Wesermarsch / Brake",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "BRB",
        "stadt_landkreis": "Brandenburg",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "BS",
        "stadt_landkreis": "Braunschweig",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "BT",
        "stadt_landkreis": "Bayreuth",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "BTF",
        "stadt_landkreis": "Bitterfeld",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "BÜS",
        "stadt_landkreis": "Büsingen am Hochrhein",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "BW",
        "stadt_landkreis": "Bundes-Wasser- und Schiffahrtsverwaltung",
        "bundesland": ""
    }, {
        "kennzeichen": "BWL",
        "stadt_landkreis": "Baden-Württemberg Landesregierung und Landtag",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "BYL",
        "stadt_landkreis": "Bayern Landesregierung und Landtag",
        "bundesland": ""
    }, {
        "kennzeichen": "BZ",
        "stadt_landkreis": "Bautzen",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "C",
        "stadt_landkreis": "Chemnitz",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "CB",
        "stadt_landkreis": "Cottbus",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "CE",
        "stadt_landkreis": "Celle",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "CHA",
        "stadt_landkreis": "Cham",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "CO",
        "stadt_landkreis": "Coburg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "COC",
        "stadt_landkreis": "Cochem-Zell",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "COE",
        "stadt_landkreis": "Coesfeld",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "CUX",
        "stadt_landkreis": "Cuxhaven",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "CW",
        "stadt_landkreis": "Calw",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "D",
        "stadt_landkreis": "Düsseldorf",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "DA",
        "stadt_landkreis": "Darmstadt-Dieburg",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "DAH",
        "stadt_landkreis": "Dachau",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "DAN",
        "stadt_landkreis": "Lüchow-Dannenberg",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "DAU",
        "stadt_landkreis": "Daun (Eifel)",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "DBR",
        "stadt_landkreis": "Bad Doberan",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "DD",
        "stadt_landkreis": "Dresden",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "DE",
        "stadt_landkreis": "Dessau-Roßlau",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "DEG",
        "stadt_landkreis": "Deggendorf",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "DEL",
        "stadt_landkreis": "Delmenhorst",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "DGF",
        "stadt_landkreis": "Dingolfing-Landau",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "DH",
        "stadt_landkreis": "Diepholz-Syke",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "DL",
        "stadt_landkreis": "Döbeln",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "DLG",
        "stadt_landkreis": "Dillingen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "DN",
        "stadt_landkreis": "Düren",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "Do",
        "stadt_landkreis": "Dortmund",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "DON",
        "stadt_landkreis": "Donauwörth",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "DU",
        "stadt_landkreis": "Duisburg",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "DÜW",
        "stadt_landkreis": "Bad Dürkheim / Neustadt/Weinstraße",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "E",
        "stadt_landkreis": "Essen",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "EA",
        "stadt_landkreis": "Eisenach",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "EB",
        "stadt_landkreis": "Eilenburg",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "EBE",
        "stadt_landkreis": "Ebersberg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "ED",
        "stadt_landkreis": "Erding",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "EE",
        "stadt_landkreis": "Elbe-Elsterkreis",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "EF",
        "stadt_landkreis": "Erfurt",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "EI",
        "stadt_landkreis": "Eichstätt",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "EIC",
        "stadt_landkreis": "Eichsfeld",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "EL",
        "stadt_landkreis": "Emsland",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "EM",
        "stadt_landkreis": "Emmendingen",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "EMD",
        "stadt_landkreis": "Emden",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "EMS",
        "stadt_landkreis": "Bad Ems/Lahn-Kreis",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "EN",
        "stadt_landkreis": "Ennepe-Ruhr-Kreis",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "ER",
        "stadt_landkreis": "Erlangen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "ERB",
        "stadt_landkreis": "Erbach/Odenwaldkreis",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "ERH",
        "stadt_landkreis": "Erlangen-Höchstadt",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "ERZ",
        "stadt_landkreis": "Erzgebirgskreis",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "ES",
        "stadt_landkreis": "Esslingen",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "ESW",
        "stadt_landkreis": "Eschwege",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "EU",
        "stadt_landkreis": "Euskirchen",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "F",
        "stadt_landkreis": "Frankfurt/Main",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "FB",
        "stadt_landkreis": "Friedberg/Wetteraukreis",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "FD",
        "stadt_landkreis": "Fulda",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "FDS",
        "stadt_landkreis": "Freudenstadt",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "FF",
        "stadt_landkreis": "Frankfurt/Oder",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "FFB",
        "stadt_landkreis": "Fürstenfeldbruck",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "FG",
        "stadt_landkreis": "Freiberg",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "FL",
        "stadt_landkreis": "Flensburg",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "FN",
        "stadt_landkreis": "Friedrichshafen/Bodenseekreis",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "FO",
        "stadt_landkreis": "Forchheim",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "FR",
        "stadt_landkreis": "Freiburg",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "FRG",
        "stadt_landkreis": "Freyung-Grafenau",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "FRI",
        "stadt_landkreis": "Friesland",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "FS",
        "stadt_landkreis": "Freising",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "FT",
        "stadt_landkreis": "Frankenthal",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "FÜ",
        "stadt_landkreis": "Fürth",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "G",
        "stadt_landkreis": "Gera",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "GAP",
        "stadt_landkreis": "Garmisch-Partenkirchen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "GE",
        "stadt_landkreis": "Gelsenkirchen",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "GER",
        "stadt_landkreis": "Germersheim",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "GF",
        "stadt_landkreis": "Gifhorn",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "GG",
        "stadt_landkreis": "Groß-Gerau",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "GI",
        "stadt_landkreis": "Gießen",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "GL",
        "stadt_landkreis": "Bergisch Gladbach",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "GM",
        "stadt_landkreis": "Gummersbach",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "GÖ",
        "stadt_landkreis": "Göttingen",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "GP",
        "stadt_landkreis": "Göppingen",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "GR",
        "stadt_landkreis": "Görlitz",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "GRZ",
        "stadt_landkreis": "Greiz",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "GS",
        "stadt_landkreis": "Goslar",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "GT",
        "stadt_landkreis": "Gütersloh",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "GTH",
        "stadt_landkreis": "Gotha",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "GÜ",
        "stadt_landkreis": "Güstrow",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "GZ",
        "stadt_landkreis": "Günzburg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "H",
        "stadt_landkreis": "Hannover",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "HA",
        "stadt_landkreis": "Hagen",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "HAL",
        "stadt_landkreis": "Halle",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "HAM",
        "stadt_landkreis": "Hamm",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "HAS",
        "stadt_landkreis": "Haßberge / Haßfurt",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "HB",
        "stadt_landkreis": "Hansestadt Bremen/Bremerhaven",
        "bundesland": "Bremen"
    }, {
        "kennzeichen": "HBN",
        "stadt_landkreis": "Hildburghausen",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "HD",
        "stadt_landkreis": "Heidelberg",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "HDH",
        "stadt_landkreis": "Heidenheim",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "HE",
        "stadt_landkreis": "Helmstedt",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "HEF",
        "stadt_landkreis": "Bad Hersfeld",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "HEI",
        "stadt_landkreis": "Heide",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "HEL",
        "stadt_landkreis": "Hessen Landesregierung und Landtag",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "HER",
        "stadt_landkreis": "Herne",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "HF",
        "stadt_landkreis": "Herford",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "HG",
        "stadt_landkreis": "Bad Homburg",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "HGW",
        "stadt_landkreis": "Hansestadt Greifswald",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "HH",
        "stadt_landkreis": "Hansestadt Hamburg",
        "bundesland": "Hamburg"
    }, {
        "kennzeichen": "HI",
        "stadt_landkreis": "Hildesheim",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "HL",
        "stadt_landkreis": "Hansestadt Lübeck",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "HM",
        "stadt_landkreis": "Hameln-Pyrmont",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "HN",
        "stadt_landkreis": "Heilbronn",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "HO",
        "stadt_landkreis": "Hof",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "HOL",
        "stadt_landkreis": "Holzminden",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "HOM",
        "stadt_landkreis": "Homburg",
        "bundesland": "Saarland"
    }, {
        "kennzeichen": "HP",
        "stadt_landkreis": "Heppenheim",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "HR",
        "stadt_landkreis": "Homberg",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "HRO",
        "stadt_landkreis": "Hansestadt Rostock",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "HS",
        "stadt_landkreis": "Heinsberg",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "HSK",
        "stadt_landkreis": "Hochsauerlandkreis",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "HST",
        "stadt_landkreis": "Hansestadt Stralsund",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "HU",
        "stadt_landkreis": "Hanau",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "HVL",
        "stadt_landkreis": "Havelland",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "HWI",
        "stadt_landkreis": "Hansestadt Wismar",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "HX",
        "stadt_landkreis": "Höxter",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "HZ",
        "stadt_landkreis": "Harz",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "IGB",
        "stadt_landkreis": "St. Ingbert",
        "bundesland": "Saarland"
    }, {
        "kennzeichen": "IK",
        "stadt_landkreis": "Ilm-Kreis",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "IN",
        "stadt_landkreis": "Ingolstadt",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "IZ",
        "stadt_landkreis": "Itzehoe",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "J",
        "stadt_landkreis": "Jena",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "JL",
        "stadt_landkreis": "Jerichower Land",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "K",
        "stadt_landkreis": "Köln",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "KA",
        "stadt_landkreis": "Karlsruhe",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "KB",
        "stadt_landkreis": "Korbach",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "KC",
        "stadt_landkreis": "Kronach",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "KE",
        "stadt_landkreis": "Kempten",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "KEH",
        "stadt_landkreis": "Kelheim",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "KF",
        "stadt_landkreis": "Kaufbeuren",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "KG",
        "stadt_landkreis": "Bad Kissingen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "KH",
        "stadt_landkreis": "Bad Kreuznach",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "KI",
        "stadt_landkreis": "Kiel",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "KIB",
        "stadt_landkreis": "Kirchheimbolanden",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "KL",
        "stadt_landkreis": "Kaiserslautern",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "KLE",
        "stadt_landkreis": "Kleve",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "KN",
        "stadt_landkreis": "Konstanz",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "KO",
        "stadt_landkreis": "Koblenz",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "KR",
        "stadt_landkreis": "Krefeld",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "KS",
        "stadt_landkreis": "Kassel",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "KT",
        "stadt_landkreis": "Kitzingen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "KU",
        "stadt_landkreis": "Kulmbach",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "KÜN",
        "stadt_landkreis": "Künzelsau",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "KUS",
        "stadt_landkreis": "Kusel",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "KYF",
        "stadt_landkreis": "Kyffhäuserkreis",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "L",
        "stadt_landkreis": "Leipzig",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "LA",
        "stadt_landkreis": "Landshut",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "LAU",
        "stadt_landkreis": "Lauf/Nürnberger Land",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "LB",
        "stadt_landkreis": "Ludwigsburg",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "LD",
        "stadt_landkreis": "Landau",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "LDK",
        "stadt_landkreis": "Lahn-Dill-Kreis",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "LDS",
        "stadt_landkreis": "Dahme-Spreewald",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "LER",
        "stadt_landkreis": "Leer/Ostfriesland",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "LEV",
        "stadt_landkreis": "Leverkusen",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "LG",
        "stadt_landkreis": "Lüneburg",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "LI",
        "stadt_landkreis": "Lindau",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "LIF",
        "stadt_landkreis": "Lichtenfels",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "LIP",
        "stadt_landkreis": "Lippe",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "LL",
        "stadt_landkreis": "Landsberg/Lech",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "LM",
        "stadt_landkreis": "Limburg-Weilburg",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "LÖ",
        "stadt_landkreis": "Lörrach",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "LOS",
        "stadt_landkreis": "Oder-Spree-Kreis Beeskow",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "LRO",
        "stadt_landkreis": "Landkreis Rostock",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "LSA",
        "stadt_landkreis": "Sachsen-Anhalt Landesregierung und Landtag",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "LSN",
        "stadt_landkreis": "Sachsen Landesregierung und Landtag",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "LU",
        "stadt_landkreis": "Ludwigshafen",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "LWL",
        "stadt_landkreis": "Ludwigslust",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "M",
        "stadt_landkreis": "München",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "MA",
        "stadt_landkreis": "Mannheim",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "MB",
        "stadt_landkreis": "Miesbach",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "MD",
        "stadt_landkreis": "Magdeburg",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "ME",
        "stadt_landkreis": "Mettmann",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "MEI",
        "stadt_landkreis": "Meißen",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "MG",
        "stadt_landkreis": "Mönchengladbach",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "MI",
        "stadt_landkreis": "Minden-Lübbecke",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "MIL",
        "stadt_landkreis": "Miltenberg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "MK",
        "stadt_landkreis": "Märkischer Kreis",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "MKK",
        "stadt_landkreis": "Main-Kinzig-Kreis",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "MM",
        "stadt_landkreis": "Memmingen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "MN",
        "stadt_landkreis": "Mindelheim",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "MOL",
        "stadt_landkreis": "Märkisch-Oderland",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "MOS",
        "stadt_landkreis": "Mosbach",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "MR",
        "stadt_landkreis": "Marburg-Biedenkopf",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "MS",
        "stadt_landkreis": "Münster",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "MSH",
        "stadt_landkreis": "Mansfeld-Südharz",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "MSP",
        "stadt_landkreis": "Main-Spessart-Kreis",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "MST",
        "stadt_landkreis": "Mecklenburg-Strelitz",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "MTK",
        "stadt_landkreis": "Main-Taunus-Kreis",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "MÜ",
        "stadt_landkreis": "Mühldorf am Inn",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "MÜR",
        "stadt_landkreis": "Müritz",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "MVL",
        "stadt_landkreis": "Mecklenburg-Vorpommern Landesregierung und Landtag",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "MYK",
        "stadt_landkreis": "Mayen-Koblenz",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "MZ",
        "stadt_landkreis": "Mainz",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "MZG",
        "stadt_landkreis": "Merzig-Wadern",
        "bundesland": "Saarland"
    }, {
        "kennzeichen": "N",
        "stadt_landkreis": "Nürnberg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "NB",
        "stadt_landkreis": "Neubrandenburg",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "ND",
        "stadt_landkreis": "Neuburg-Schrobenhausen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "NDH",
        "stadt_landkreis": "Nordhausen",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "NE",
        "stadt_landkreis": "Neuss",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "NEA",
        "stadt_landkreis": "Neustadt-Bad Windsheim/Aisch",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "NES",
        "stadt_landkreis": "Bad Neustadt/Saale",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "NEW",
        "stadt_landkreis": "Neustadt/Waldnaab",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "NF",
        "stadt_landkreis": "Nordfriesland",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "NI",
        "stadt_landkreis": "Nienburg",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "NK",
        "stadt_landkreis": "Neunkirchen",
        "bundesland": "Saarland"
    }, {
        "kennzeichen": "NL",
        "stadt_landkreis": "Niedersachsen Landesregierung und Landtag",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "NM",
        "stadt_landkreis": "Neumarkt/Oberpfalz",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "NMS",
        "stadt_landkreis": "Neumünster",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "NOH",
        "stadt_landkreis": "Nordhorn",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "NOM",
        "stadt_landkreis": "Northeim",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "NR",
        "stadt_landkreis": "Neuwied",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "Nordrhein-Westfalen",
        "stadt_landkreis": "Nordrhein-Westfalen Landesregierung und Landtag",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "NU",
        "stadt_landkreis": "Neu-Ulm",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "NVP",
        "stadt_landkreis": "Nordvorpommern",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "NW",
        "stadt_landkreis": "Neustadt/Weinstraße",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "NWM",
        "stadt_landkreis": "Nordwestmecklenburg",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "OA",
        "stadt_landkreis": "Oberallgäu / Sonthofen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "OAL",
        "stadt_landkreis": "Ostallgäu",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "OB",
        "stadt_landkreis": "Oberhausen",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "OD",
        "stadt_landkreis": "Bad Oldesloe",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "OE",
        "stadt_landkreis": "Olpe",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "OF",
        "stadt_landkreis": "Offenbach/Main",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "OG",
        "stadt_landkreis": "Offenburg",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "OH",
        "stadt_landkreis": "Ostholstein / Eutin",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "OHA",
        "stadt_landkreis": "Osterode/Harz",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "OHV",
        "stadt_landkreis": "Oberhavel",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "OHZ",
        "stadt_landkreis": "Osterholz-Scharmbeck",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "OL",
        "stadt_landkreis": "Oldenburg",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "OPR",
        "stadt_landkreis": "Ostprignitz-Ruppin",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "OS",
        "stadt_landkreis": "Osnabrück",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "OSL",
        "stadt_landkreis": "Oberspreewald-Lausitz",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "OVP",
        "stadt_landkreis": "Ostvorpommern",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "P",
        "stadt_landkreis": "Potsdam",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "PA",
        "stadt_landkreis": "Passau",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "PAF",
        "stadt_landkreis": "Pfaffenhofen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "PAN",
        "stadt_landkreis": "Pfarrkirchen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "PB",
        "stadt_landkreis": "Paderborn",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "PCH",
        "stadt_landkreis": "Parchim",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "PE",
        "stadt_landkreis": "Peine",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "PF",
        "stadt_landkreis": "Pforzheim",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "PI",
        "stadt_landkreis": "Pinneberg",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "PIR",
        "stadt_landkreis": "Pirna/Sächsische Schweiz",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "PLÖ",
        "stadt_landkreis": "Plön",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "PM",
        "stadt_landkreis": "Potsdam-Mittelmark",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "PR",
        "stadt_landkreis": "Prignitz / Perleberg",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "PS",
        "stadt_landkreis": "Pirmasens",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "R",
        "stadt_landkreis": "Regensburg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "RA",
        "stadt_landkreis": "Rastatt",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "RD",
        "stadt_landkreis": "Rendsburg-Eckernförde",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "RE",
        "stadt_landkreis": "Recklinghausen",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "REG",
        "stadt_landkreis": "Regen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "RO",
        "stadt_landkreis": "Rosenheim",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "ROS",
        "stadt_landkreis": "Rostock/Landkreis",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "ROW",
        "stadt_landkreis": "Rotenburg/Wümme",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "RP",
        "stadt_landkreis": "Rhein-Pfalz-Kreis",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "RPL",
        "stadt_landkreis": "Rheinland-Pfalzlz Landesregierung und Landtag",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "RS",
        "stadt_landkreis": "Remscheid",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "RT",
        "stadt_landkreis": "Reutlingen",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "RÜD",
        "stadt_landkreis": "Rüdesheim",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "RÜG",
        "stadt_landkreis": "Rügen",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "RV",
        "stadt_landkreis": "Ravensburg",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "RW",
        "stadt_landkreis": "Rottweil",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "RZ",
        "stadt_landkreis": "Ratzeburg",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "S",
        "stadt_landkreis": "Stuttgart",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "SAD",
        "stadt_landkreis": "Schwandorf",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "SAL",
        "stadt_landkreis": "Saarland Landesregierung und Landtag",
        "bundesland": "Saarland"
    }, {
        "kennzeichen": "SAW",
        "stadt_landkreis": "Salzwedel",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "SB",
        "stadt_landkreis": "Saarbrücken",
        "bundesland": "Saarland"
    }, {
        "kennzeichen": "SC",
        "stadt_landkreis": "Schwabach",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "SDL",
        "stadt_landkreis": "Stendal",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "SE",
        "stadt_landkreis": "Bad Segeberg",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "SG",
        "stadt_landkreis": "Solingen",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "SH",
        "stadt_landkreis": "Schleswig-Holstein Landesregierung und Landtag",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "SHA",
        "stadt_landkreis": "Schwäbisch Hall",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "SHG",
        "stadt_landkreis": "Stadthagen",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "SHK",
        "stadt_landkreis": "Saale-Holzlandkreis",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "SHL",
        "stadt_landkreis": "Suhl",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "SI",
        "stadt_landkreis": "Siegen-Wittgenstein",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "SIG",
        "stadt_landkreis": "Sigmaringen",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "SIM",
        "stadt_landkreis": "Rhein-Hunsrück-Kreis",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "SK",
        "stadt_landkreis": "Saalkreis",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "SL",
        "stadt_landkreis": "Schleswig-Flensburg",
        "bundesland": "Schleswig-Holstein"
    }, {
        "kennzeichen": "SLF",
        "stadt_landkreis": "Saalfeld-Rudolstadt",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "SLK",
        "stadt_landkreis": "Salzlandkreis",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "SLS",
        "stadt_landkreis": "Saarlouis",
        "bundesland": "Saarland"
    }, {
        "kennzeichen": "SM",
        "stadt_landkreis": "Schmalkalden-Meiningen",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "SN",
        "stadt_landkreis": "Schwerin",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "SO",
        "stadt_landkreis": "Soest",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "SOK",
        "stadt_landkreis": "Saale-Orla-Kreis",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "SÖM",
        "stadt_landkreis": "Sömmerda",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "SON",
        "stadt_landkreis": "Sonneberg",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "SP",
        "stadt_landkreis": "Speyer",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "SPN",
        "stadt_landkreis": "Spree-Neiße",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "SR",
        "stadt_landkreis": "Straubing-Bogen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "ST",
        "stadt_landkreis": "Steinfurt",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "STA",
        "stadt_landkreis": "Starnberg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "STD",
        "stadt_landkreis": "Stade",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "SU",
        "stadt_landkreis": "Siegburg",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "SÜW",
        "stadt_landkreis": "Südliche Weinstraße",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "SW",
        "stadt_landkreis": "Schweinfurt",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "SZ",
        "stadt_landkreis": "Salzgitter",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "TDO",
        "stadt_landkreis": "Nordsachsen",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "TBB",
        "stadt_landkreis": "Tauberbischofsheim",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "TF",
        "stadt_landkreis": "Teltow-Fläming",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "TG",
        "stadt_landkreis": "Torgau",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "THL",
        "stadt_landkreis": "Thüringen Landesregierung und Landtag",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "THW",
        "stadt_landkreis": "Technisches Hilfswerk",
        "bundesland": ""
    }, {
        "kennzeichen": "TIR",
        "stadt_landkreis": "Tirschenreuth",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "TÖL",
        "stadt_landkreis": "Bad Tölz",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "TR",
        "stadt_landkreis": "Trier",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "TS",
        "stadt_landkreis": "Traunstein",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "TÜ",
        "stadt_landkreis": "Tübingen",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "TUT",
        "stadt_landkreis": "Tuttlingen",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "UE",
        "stadt_landkreis": "Uelzen",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "UL",
        "stadt_landkreis": "Ulm",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "UM",
        "stadt_landkreis": "Uckermark",
        "bundesland": "Brandenburg"
    }, {
        "kennzeichen": "UN",
        "stadt_landkreis": "Unna",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "V",
        "stadt_landkreis": "Vogtlandkreis",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "VB",
        "stadt_landkreis": "Vogelsbergkreis",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "VEC",
        "stadt_landkreis": "Vechta",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "VER",
        "stadt_landkreis": "Verden",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "VIE",
        "stadt_landkreis": "Viersen",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "VK",
        "stadt_landkreis": "Völklingen",
        "bundesland": "Saarland"
    }, {
        "kennzeichen": "VR",
        "stadt_landkreis": "Vorpommern-Rügen",
        "bundesland": "Mecklenburg-Vorpommern"
    }, {
        "kennzeichen": "VS",
        "stadt_landkreis": "Villingen-Schwenningen",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "W",
        "stadt_landkreis": "Wuppertal",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "WAF",
        "stadt_landkreis": "Warendorf",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "WAK",
        "stadt_landkreis": "Wartburgkreis",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "WB",
        "stadt_landkreis": "Wittenberg",
        "bundesland": "Sachsen-Anhalt"
    }, {
        "kennzeichen": "WE",
        "stadt_landkreis": "Weimar",
        "bundesland": "Thüringen"
    }, {
        "kennzeichen": "WEN",
        "stadt_landkreis": "Weiden",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "WES",
        "stadt_landkreis": "Wesel",
        "bundesland": "Nordrhein-Westfalen"
    }, {
        "kennzeichen": "WF",
        "stadt_landkreis": "Wolfenbüttel",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "WHV",
        "stadt_landkreis": "Wilhelmshaven",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "WI",
        "stadt_landkreis": "Wiesbaden",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "WIL",
        "stadt_landkreis": "Wittlich",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "WL",
        "stadt_landkreis": "Winsen/Luhe",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "WM",
        "stadt_landkreis": "Weilheim-Schongau",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "WN",
        "stadt_landkreis": "Waiblingen",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "WND",
        "stadt_landkreis": "St. Wendel",
        "bundesland": "Saarland"
    }, {
        "kennzeichen": "WO",
        "stadt_landkreis": "Worms",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "WOB",
        "stadt_landkreis": "Wolfsburg",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "WST",
        "stadt_landkreis": "Westerstede",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "WT",
        "stadt_landkreis": "Waldshut-Tiengen",
        "bundesland": "Baden-Württemberg"
    }, {
        "kennzeichen": "WTM",
        "stadt_landkreis": "Wittmund",
        "bundesland": "Niedersachsen"
    }, {
        "kennzeichen": "WÜ",
        "stadt_landkreis": "Würzburg",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "WUG",
        "stadt_landkreis": "Weißenburg-Gunzenhausen",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "WUN",
        "stadt_landkreis": "Wunsiedel",
        "bundesland": "Bayern"
    }, {
        "kennzeichen": "WW",
        "stadt_landkreis": "Westerwald",
        "bundesland": "Rheinland-Pfalz"
    }, {
        "kennzeichen": "WZ",
        "stadt_landkreis": "Wetzlar",
        "bundesland": "Hessen"
    }, {
        "kennzeichen": "Y",
        "stadt_landkreis": "Bundeswehr",
        "bundesland": ""
    }, {
        "kennzeichen": "Z",
        "stadt_landkreis": "Zwickau",
        "bundesland": "Sachsen"
    }, {
        "kennzeichen": "ZW",
        "stadt_landkreis": "Zweibrücken",
        "bundesland": "Rheinland-Pfalz"
    }];
