import { render, staticRenderFns } from "./Password.vue?vue&type=template&id=9ac04480&scoped=true"
import script from "./Password.vue?vue&type=script&lang=ts"
export * from "./Password.vue?vue&type=script&lang=ts"
import style0 from "./Password.vue?vue&type=style&index=0&id=9ac04480&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ac04480",
  null
  
)

export default component.exports