"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vuexMapFields = require("vuex-map-fields");
const getField = vuexMapFields.getField;
const updateField = vuexMapFields.updateField;
const namespaced = true;
const state = {
    highlighted: [],
    city: ""
};
const getters = {
    getField: getField,
    getCity: (state) => state.city,
    getHighlighted: (state) => state.highlighted
};
const actions = {};
const mutations = {
    updateField: updateField,
    addHighlighted: (state, el) => { state.highlighted.push(el); },
    removeHighlighted: (state) => { state.highlighted = []; }
};
exports.default = {
    namespaced, state, getters, actions, mutations
};
