<template>
  <nav id="footer" class="navbar navbar-expand-lg bottom navbar-light bg-light">
    <div class="container">
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link
              class="nav-link"
              active-class="active"
              to="/impressum"
              exact=""
              >Impressum</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" to="/agb"
              >AGB</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              active-class="active"
              to="/datenschutz"
              >Datenschutz</router-link
            >
          </li>
          <!--<li class="nav-item">
            <router-link class="nav-link" active-class="active" to="/kontakt">Kontakt</router-link>
          </li>-->
        </ul>
        <ul class="navbar-nav ml-auto">
          © Kennzeichenbörse.com - created with ❤ by Scriptless
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 56px; /* Footer height */
}
</style>
