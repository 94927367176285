<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-light">
    <div class="container">
      <router-link class="navbar-brand" to="/">Kennzeichenbörse</router-link>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarColor01">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" to="/" exact="">Börse</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" to="/suche">Kürzelsuche</router-link>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link v-if="login" class="nav-link" active-class="active" to="/account">Account</router-link>
          </li>     
        </ul>
        <form class="form-inline my-2 my-lg-0">
            <div v-if="login">
              <router-link class="btn btn-primary my-2 my-sm-0 mr-2 ml-2" to="/add">Kennzeichen hinzufügen</router-link>
              <router-link class="btn btn-danger my-2 my-sm-0 mr-2" to="/logout">Logout</router-link>
            </div>
            <div v-else>
              <router-link class="btn btn-primary my-2 my-sm-0" to="/login">Login</router-link>
            </div>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
import AuthService from '../services/AuthService';

export default {
  name: 'Navbar',
  data() {
    return {
      login: false,
      email: null
    }
  },
  created() {
    this.login = this.$store.getters['user/isLoggedIn'];
  },
  computed: {
    status() {
      return this.$store.getters['user/isLoggedIn'];
    }
  },
  watch: {
    status (loggedin) {
      this.login = loggedin;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
   body:not(#main) {
     padding-top: 100px;
   }
</style>