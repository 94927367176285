<template>
    <div id="kennzeichen">
		<img id="kennzeichen-image" src="../assets/images/kennzeichen.png">
		<div class="kennzeichen-input">
            <div v-if="add">
                <slot></slot>
            </div>
            <div v-else>
                <div v-if="search">
                    <slot></slot>
                </div>
                <div v-else>
                    <p class="kennzeichen" id="kz_ort">{{ort}}</p>
                </div>
                <p class="kennzeichen" id="kz_rest">{{buchstaben}} {{zahlen}}</p>
            </div>
        </div>
	</div>
</template>

<script>
export default {
  name: 'Kennzeichen',
  props: {
      search: {
          type: Boolean,
          default: false
      },
      add: {
          type: Boolean,
          default: false
      },
      ort: {
          type: String,
          default: "XX"
      },
      buchstaben: {
          type: String,
          default: "XX"
      },
      zahlen: {
          type: String,
          default: "XXX"
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: 'EuroPlate';
  src: url(../assets/fonts/EuroPlate.ttf);
}

#kennzeichen {
    padding: 1rem;
}

#kennzeichen-image {
    width: auto; 
    height: 4.5rem
}

.kennzeichen {
    position: absolute;
    height: 3rem;
    font-family: 'EuroPlate';
    font-size: 3rem;
}

#kz_input_ort {
    margin-top: -3.7rem;
    margin-left: 3rem;
    width: 5rem;
    border: 0.1rem solid #f0f0f0;
}

#kz_input_buchstaben {
    margin-top: -3.7rem;
    margin-left: 11rem;
    width: 3.25rem;
    border: 0.1rem solid #f0f0f0;
}

#kz_input_zahlen {
    margin-top: -3.7rem;
    margin-left: 14.75rem;
    width: 6.5rem;
    border: 0.1rem solid #f0f0f0;
}

#kz_ort {
    margin-top: -4.4rem;
    margin-left: 3.5rem;
    width: 5rem;
}

#kz_rest {
    margin-top: -4.4rem;
    margin-left: 11rem;
    width: 10rem;
}
</style>